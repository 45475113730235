'use strict';

import {forEach, isOk, getElementById, showHide, lazyload} from './tools'

const deleteMsg = "本当に削除しますか？"

window.addEventListener('load', () => {
  forEach(
    document.getElementsByClassName('deleteConfirm'),
    elm => {
      const url = elm.getAttribute('accept-to')
      if(!url){return;}
      if(elm.tagName==='FORM'){
        elm.addEventListener('submit', (ev)=>{
          ev.preventDefault();
          if(confirm(deleteMsg)){
            elm.action = url
            elm.submit()
            return
          };
        })
        for(const b of elm.getElementsByTagName('button')){
          b.disabled = false
        }
      }else{
        elm.addEventListener('click', (ev)=>{
          ev.preventDefault();
          if(confirm(deleteMsg)){
            window.location = url;
          };
        })
      }
      elm.style.display = 'block'
    }
  )
})

window.addEventListener('load', ()=>{
  forEach(document.getElementsByClassName('limitedfile'), f=>{
    f.addEventListener('change', ev=>{
      ev.preventDefault()
      if(ev.target.files[0]!=undefined){
        if(ev.target.files[0].size > 9*1024*1024){
          ev.target.files = (new DataTransfer()).files
          document.getElementById(f.getAttribute('for')).style.display='block'
        }else{
          document.getElementById(f.getAttribute('for')).style.display='none'
        }
      }
    })
  })
})


// コメント投稿フォーム処理
const useCommentCreate = (entries, getComment) => {
  forEach(entries.getElementsByClassName('commentForm'), form => {
    const entryId = form.getAttribute('entryId')
    if(!entryId) {return}

    let lock = false

    form.addEventListener('submit', ev =>{
      ev.preventDefault()
      if(lock){
        return
      }

      lock = true
      ev.target.classList.add('loading')

      const body = new FormData(form)

      fetch(form.action,{
        method: form.method,
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Ajax': 'yes'
        },
        body
      })
        .then(r=>{
          if(isOk(r)){
            form.reset()
            getComment(entryId)
          }
        })
        .finally(()=>{
          ev.target.classList.remove('loading')
          lock = false
        })
    })
  })
}

const useReadCheckShow = (entries) => {
  let rclock = false
  const rcSpinner = document.getElementById('readCheckBodySpinner')
  const rcBody = document.getElementById('readCheckModalBody')

  const setReadcheckButton = (button) => {
    const entryId = button.getAttribute('entryId')
    if(entryId) {
      button.addEventListener('click', ev=>{
        ev.preventDefault()
        if(rclock) {return}
        rclock = true

        ev.target.classList.add('processing')
        document.getElementById('showReadCheckModal').click()
        rcSpinner.style.display = 'inline-block'
        rcBody.innerHTML = ''

        fetch(`${baseUrl}readchecks/read_user/${entryId}`)
          .then(r=>{
            rcSpinner.style.display = 'none'
            if(r.ok) {
              r.text().then(b=>{
                rcBody.innerHTML = b
                forEach(rcBody.getElementsByClassName('nav-link'), a=>{
                  const c = a.getAttribute('for')
                  a.addEventListener('click', ev=>{
                    forEach(document.getElementsByClassName('nav-link'), b=>{
                      b.classList.remove('active')
                    })
                    forEach(document.getElementsByClassName('readersBox'), b=>{
                      if(b.id===c){
                        b.classList.remove('d-none')
                      }else{
                        b.classList.add('d-none')
                      }
                    })
                    a.classList.add('active')
                  })
                })
              })
            }else{
              rcBody.innerHTML = 'エラーが発生しました'
            }
          })
          .catch(er=>{
            rcSpinner.style.display = 'none'
            rcBody.innerHTML = 'エラーが発生しました'
          })
          .finally(()=>{
            ev.target.classList.remove('processing')
            rclock = false
          })
      })
    }
  }

  const setup = (parent) => {
    forEach(parent.getElementsByClassName('readCheckButton'), setReadcheckButton)
  }

  setup(entries)

  return setup
}

const useDeleteAction = (entries) => {
  let targetId = null

  const setDeleteAction = (parent) => {
    forEach(parent.getElementsByClassName('deleteButton'), db => {
      const entryId = db.getAttribute('entry_id')
      if (!entryId) {return}

      db.addEventListener('click', (ev) => {
        if(targetId!==null){return}
        targetId = entryId
      })
    })
  }

  setDeleteAction(entries)

  const dm = getElementById('deleteModal')
  if(!dm){return}
  dm.addEventListener('show.bs.modal', ev => {
    targetId = null
    dm.classList.remove('success', 'fail')
  })

  forEach(dm.getElementsByClassName('delButton'), db => {
    db.addEventListener('click', ev=>{
      if (targetId === null){return}

      forEach(dm.getElementsByClassName('spinner'), f=>{
        f.classList.remove('d-none')
      })

      fetch(baseUrl+'entries/del/'+targetId)
        .then(r=>{
          if(r.ok){
            dm.classList.add('success')
            const e = getElementById('entry'+targetId)
            if(e){
              e.classList.add('deleted')
              setTimeout(() => {
                const a = getElementById('entry'+targetId)
                if(a){a.remove()}
              }, 400)
            }
            return
          }
          dm.classList.add('fail')
        })
        .catch(err=>{
          dm.classList.add('fail')
        })
        .finally(() => {
          forEach(dm.getElementsByClassName('spinner'), f=>{
            f.classList.add('d-none')
          })
        })
    })
  })

  return setDeleteAction
}

window.addEventListener('load', () => {
  const selector = getElementById('userStatusYearSelector')
  const area = getElementById('userStatusYearArea')
  const spinner = getElementById('userStatusSpinner')

  if(!selector||!area) {
    return;
  }

  selector.addEventListener('change', (ev)=>{
    if(selector.value != '') {
      let userId = selector.getAttribute('user_id')
      if(spinner) {
        spinner.classList.remove('d-none')
      }

      fetch(baseUrl+'users/ajax_userdata/'+userId+'/'+selector.value)
        .then(r=>{
          if(r.ok){
            r.text().then((w)=>{
              area.innerHTML = w
            })
          }
        })
        .finally(()=>{
          if(spinner) {
            spinner.classList.add('d-none')
          }
        })
    }
  })
})


// いいね・ピックアップ処理
window.addEventListener('load', () => {
  const entries = getElementById('entries')
  if(!entries){return}

  let lock = false
  const showed = 'showed'

  let ids = [];
  let inter = null

  const checkReads = () =>{
    if(lock){
      return;
    }

    const h = window.scrollY + (window.screen.height * 0.45)

    for(const entry of entries.getElementsByClassName('entry')){
      if(entry.offsetTop >= h){
        break;
      }

      if(entry.getAttribute(showed) === null){
        ids.push(entry.getAttribute('entry_id'))
      }
    }

    for(const entry of entries.getElementsByClassName('comment')){
      if(entry.offsetTop >= h){
        break;
      }

      if(entry.getAttribute(showed) === null){
        ids.push(entry.getAttribute('entry_id'))
        entry.setAttribute(showed, 'true')
      }
    }

    if(ids.filter(a=>a!=='').length > 0) {
      lock = true
      const queue = Array.from(new Set(ids)).filter(a=>a!=='')
      ids = []

      fetch(baseUrl+'entries/read_bulkinsert/', {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(queue)
      })
        .then(r=>{
          if(isOk(r)){
            for(const id of queue){
              const entry = getElementById(`entry${id}`)
              if(entry){
                entry.setAttribute(showed, 'true')
                entry.classList.add('showSent')
              }
            }
          }
        })
        .finally(()=>{
          lock = false
        })
    }
  }

  setTimeout(()=>{
    checkReads()
    inter = setInterval(checkReads, 3000)
  }, 800)

  const setLikesGetAction = (entry) => {
    const entryId = entry.getAttribute('entry_id')
    const area = entry.getElementsByClassName('likes')[0]
    const contentArea = area.getElementsByClassName('likes_content')[0];

    forEach(entry.getElementsByClassName('show_likes'), (c) => {
      if(c.getAttribute('seted')){return}
      if(c.getAttribute('entry_id')!=entryId){return}
      if(!contentArea){return}

      let lock = false;
      c.addEventListener('click', ev => {
        ev.preventDefault()
        if(lock){return}
        lock = true
        area.classList.add('loading')
        contentArea.innerHTML = ''

        fetch(baseUrl+'likes/blist/'+entryId).then(r=>{
          if(isOk(r)){
            r.text().then(w=>{
              c.setAttribute('filled', true)
              contentArea.innerHTML = w
            })
          }
        }).finally(() => {
          area.classList.remove('hide')
          area.classList.remove('loading')
          lock = false
        })
      })
      c.setAttribute('seted', true)
    })
  }

  const setLikeButtonAction = (entry) => {
    const entryId = entry.getAttribute('entry_id')

    forEach(entry.getElementsByClassName('like-button'), c=>{
      if(c.getAttribute('seted')){return}
      if(c.getAttribute('entry_id')!=entryId){return}

      let lock = false
      c.addEventListener('click', ev=>{
        ev.preventDefault()
        if(lock){return}
        lock = true

        fetch(baseUrl+'likes/add/'+entryId).then(r=>{
          if(isOk(r)){
            r.json().then(w=>{
              forEach(entry.getElementsByClassName('likes_count'), a=>{
                if(a.getAttribute('entry_id')==entryId){
                  a.innerHTML = w
                }
              })
            })

            const sl = entry.getElementsByClassName('show_likes')[0]
            if(sl&&sl.getAttribute('filled')){
              sl.click()
            }

            c.disabled = true
          }
        }).finally(() => {
          lock = false
        })
      })
      c.setAttribute('seted', true)
    })
  }

  const setPickupAction = (entry) => {
    forEach(entry.getElementsByClassName('pickup-button'), button=>{
      let lock = false
      button.addEventListener('click', ev=>{
        ev.preventDefault()
        if(lock){return}
        lock = true
        fetch(baseUrl+'entries/pickup/'+button.getAttribute('entry_id')+'/'+entry.getAttribute('entry_user_id')+'/'+button.getAttribute('pickup_type')+'/'+button.getAttribute('pickup_point'))
          .then((r) => {
            if(isOk(r)){
              r.json().then((z)=>{
                const b = document.getElementById(button.getAttribute('disabled_for'))
                if(b){
                  b.disabled = true
                }
              })
            }
          })
          .finally(()=>{
            lock = false
          })
      })
    })
  }

  const setupReadCheckShow = useReadCheckShow(entries)
  const setupDeleteAction = useDeleteAction(entries)

  let commentLock = false
  const getComment = async (entryId) => {
    if(commentLock){
      return
    }
    commentLock = true
    const entry = document.getElementById(`entry${entryId}`)
    const f = document.getElementById(`comments${entryId}`)
    const bd = document.getElementById(`commentBody${entryId}`)

    f.classList.remove('close')
    f.classList.add('loading')
    bd.innerHTML = ''

    fetch(baseUrl+'entries/comment_display/'+entryId)
      .then(r=>{
        if(isOk(r)) {
          r.text().then(b=>{
            bd.innerHTML = b
            setupReadCheckShow(bd)
            setupDeleteAction(bd)
            forEach(bd.getElementsByClassName('comment'), (entry)=>{
              setLikesGetAction(entry)
              setLikeButtonAction(entry)
              setPickupAction(entry)
            })
          })
        }
      })
      .finally(()=>{
        commentLock = false
        f.classList.remove('loading')
      })

    return bd
  }

  useCommentCreate(entries, getComment)

  forEach(entries.getElementsByClassName('comment-button'), (btn) => {
    const entryId = btn.getAttribute('entryId')
      btn.addEventListener('click', ev => {
        ev.preventDefault()
        const f = document.getElementById(`comments${entryId}`)

        if(!f.classList.contains('close')) {
          f.classList.add('close')
          return
        }

        btn.disabled = true
        getComment(entryId)
          .finally(()=>{
            btn.disabled = false
          })
      })
  })

  forEach(entries.getElementsByClassName('entry'), setLikesGetAction)
  forEach(entries.getElementsByClassName('comment'), setLikesGetAction)

  forEach(entries.getElementsByClassName('entry'), setLikeButtonAction)
  forEach(entries.getElementsByClassName('comment'), setLikeButtonAction)

  forEach(entries.getElementsByClassName('entry'), setPickupAction)
})


// 左メニューの未読数取得
window.addEventListener('load', () => {
  const nippou = getElementById('side-nippou')
  if (nippou) {
    fetch(baseUrl+'entries/read_count_store_check/')
    .then((r) => {
      if(isOk(r)){
        r.json().then(res=>{
          forEach(nippou.getElementsByTagName('li'), li=>{
            const id = li.getAttribute('store_id')
            const a = li.getElementsByTagName('a')[0]
            if(id&&a){
              for(let i=0;i<res.read_count.length;i++){
                if(id == res.read_count[i].store) {
                  if(res.read_count[i].count > 0){
                    let b = document.createElement('span')
                    b.classList.add('badge', 'rounded-pill', 'bg-primary')
                    b.innerHTML = res.read_count[i].count
                    a.appendChild(b)
                  }

                  if(res.read_count[i].comment_count > 0){
                    let c = document.createElement('span')
                    c.classList.add('badge', 'rounded-pill', 'bg-warning')
                    c.innerHTML = res.read_count[i].comment_count
                    a.appendChild(c)
                  }
                  break;
                }
              }
            }
          })
        })
      }
    })
  }

  const comm = getElementById('side-communities')
  if (comm) {
    fetch(baseUrl+'entries/read_count_check/')
    .then((r) => {
      if(isOk(r)){
        r.json().then(res=>{
          forEach(comm.getElementsByTagName('li'), li=>{
            const id = li.getAttribute('community_id')
            const a = li.getElementsByTagName('a')[0]

            if(id&&a){
              for(let i=0;i<res.read_count.length;i++){
                if(id == res.read_count[i].commu_group) {
                  if(res.read_count[i].count > 0){
                    let b = document.createElement('span')
                    b.classList.add('badge', 'rounded-pill', 'bg-primary')
                    b.innerHTML = res.read_count[i].count
                    a.appendChild(b)
                  }

                  if(res.read_count[i].comment_count > 0){
                    let c = document.createElement('span')
                    c.classList.add('badge', 'rounded-pill','bg-warning')
                    c.innerHTML = res.read_count[i].comment_count
                    a.appendChild(c)
                  }
                  break;
                }
              }
            }
          })
        })
      }
    })
  }
})
